/* eslint-disable theme-colors/no-literal-colors */
import { styled } from '@superset-ui/core';

export const StyledVersion = styled.div`
  color: #ccc;
  font-size: 8px;
  position: absolute;
  top: 4px;
  right: 6px;
`;
